import { useEffect, useState } from 'react'
import './DataPopover.scss'
import { LiveDataSummaryStation } from '../../types/LiveDataSummaryStation'
import { FixTypeLater } from '../../types/FixTypeLater'

interface DataPopoverProps {
  show: boolean
  stationSummary: LiveDataSummaryStation
}

const DataPopover = ({ stationSummary }: DataPopoverProps): JSX.Element => {
  const { organizationKey, stationKey } = stationSummary

  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  useEffect(() => {
    const marker = document.querySelector(
      '.mapboxgl-canvas-container .hm-ExpandedMarker'
    )?.parentElement?.parentElement

    marker?.classList.add('OnTop')

    if (isLoaded) {
      // Mount to the data popover
      try {
        ;(window as FixTypeLater).seaStats.mount('.hm-DataPopover', {
          organizationKey,
          stationKey,
        })
      } catch (e) {
        console.error(e)
      }
    }

    return () => {
      try {
        ;(window as FixTypeLater).seaStats.unmount()
      } catch (e) {
        console.error('Error unmounting.')
      }
    }
  }, [organizationKey, stationKey, isLoaded])

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <>
      <div className="hm-DataPopover" key={1} />
    </>
  )
}

export default DataPopover
