import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import DataWrapper from '../Map/DataWrapper'
import { BrowserRouter } from 'react-router-dom'

import './App.scss'
import { HydrophoneMapOptions } from '../..'

//   "homepage": "https://plotandscatter.com/wp-content/themes/Avada-Child-Theme/hydrophone-map",
// "homepage": "https://plotandscatter.com/hydrophone-map",

// TODO: Adjust as makes sense
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  },
})

function App({ options }: { options: HydrophoneMapOptions }) {
  // useEffect(() => {
  //   function logit() {
  //     console.log('sea stats loaded')
  //   }

  //   window.addEventListener('SeaStats_Loaded', logit)

  //   return () => {
  //     window.removeEventListener('SeaStats_Loaded', logit)
  //   }
  // }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="hm-App">
          <DataWrapper options={options} />
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
