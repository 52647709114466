import { useCallback, useMemo, useRef, useState } from 'react'
import Map, { MapRef, NavigationControl, ScaleControl } from 'react-map-gl'
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from 'react-router-dom'
import slugify from 'slugify'

import { Hydrophone } from '../../types/Hydrophone'
import { Legend } from '../Legend/Legend'
import { Legend as LegendType } from '../../types/Legend'
import MarkerLayer from './Marker/MarkerLayer'
import { FixTypeLater } from '../../types/FixTypeLater'
import { LiveDataSummaryStation } from '../../types/LiveDataSummaryStation'
import { useWindowWidth } from '../../utils/useWindowWidth'
import { HydrophoneMapOptions } from '../..'
// import ResetControl from './ResetControl'
// import HelloWorldControl from './ResetControl'
// import DrawControl from './ResetControl'

interface MapWrapperProps {
  markers: Hydrophone[]
  options: HydrophoneMapOptions
  legend: LegendType
  liveDataSummary: {
    organizations: Record<string, FixTypeLater>
    stations: LiveDataSummaryStation[]
  }
}

const MapWrapper = ({
  options,
  markers,
  legend,
}: MapWrapperProps): JSX.Element => {
  // const { isMobile } = useWindowWidth()

  const [viewState, setViewState] = useState({
    latitude: options.initialLatitude,
    longitude: options.initialLongitude,
    zoom: options.initialZoom,
  })

  const mapRef = useRef<MapRef>(null)

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const showFullReadings = searchParams.get('showFullReadings') === 'true'

  const interactionState = useMemo(
    () => ({
      boxZoom: !showFullReadings,
      dragRotate: !showFullReadings,
      dragPan: !showFullReadings,
      keyboard: !showFullReadings,
      doubleClickZoom: !showFullReadings,
      touchZoomRotate: !showFullReadings,
    }),
    [showFullReadings]
  )

  const zoomTo = useCallback(
    (hydrophoneName: string | null) => {
      const search = hydrophoneName
        ? createSearchParams({
            stationId: slugify(hydrophoneName),
          })
        : ''

      navigate({
        search: search.toString(),
      })
    },
    [navigate]
  )

  return (
    <Map
      {...viewState}
      {...interactionState}
      ref={mapRef}
      style={{ width: '100%', height: '100vh' }}
      mapStyle="mapbox://styles/hangler/clbxy8ou9000b15qqmh8awwwx"
      onMove={(evt) => setViewState(evt.viewState)}
      // onMoveEnd={(evt) => setViewState(evt.viewState)}
      mapboxAccessToken="pk.eyJ1IjoiaGFuZ2xlciIsImEiOiJjbGJ3aTJzMHYwaW9jM29yOTZ4cWd5MWN6In0.FvpJdXQqVM8wYV0lzvytEg"
      scrollZoom={false}
      onClick={(event) => {
        const originalTarget = event.originalEvent.target as HTMLElement

        let isStationLabelOrExpandedArea = false
        let target: HTMLElement | null = originalTarget

        while (target !== null && isStationLabelOrExpandedArea !== true) {
          isStationLabelOrExpandedArea =
            target.classList.contains('hm-StationLabel') ||
            target.classList.contains('hm-ExpandedArea')
          target = target.parentElement
        }

        if (!isStationLabelOrExpandedArea) {
          // The user did not click inside any marker. Close the popup.
          zoomTo(null)
        }
      }}
    >
      <MarkerLayer
        hydrophones={markers}
        zoomTo={zoomTo}
        viewState={viewState}
        mapRef={mapRef}
      />
      <NavigationControl position="top-right" visualizePitch />
      <ScaleControl maxWidth={200} />
      {/* <DrawControl
        position="top-left"
        displayControlsDefault={false}
        controls={{
          polygon: true,
          trash: true,
        }}
        defaultMode="draw_polygon"
      /> */}
      <Legend
        hydrophones={markers}
        legend={legend}
        zoomTo={zoomTo}
        lowZIndex={showFullReadings}
        zoomValue={viewState.zoom}
      />
    </Map>
  )
}

export default MapWrapper
