import { Marker } from 'react-map-gl'

import { Hydrophone } from '../../../types/Hydrophone'
import { networkSlug } from '../../../utils/StationSlug'
import { SuperclusterSinglePoint } from '../../../types/Cluster'
import ExpandedSingleMarker from './ExpandedSingleMarker'

import './Popup.scss'

interface SingleMarkerProps {
  isExpanded: boolean
  cluster: SuperclusterSinglePoint<Hydrophone>
  zoomTo: (name: string | null) => void
}

const SingleMarker = ({
  isExpanded,
  cluster,
  zoomTo,
}: SingleMarkerProps): JSX.Element => {
  const hydrophone = cluster.properties

  const colorClass = networkSlug(hydrophone.Territory)

  const onClickCallback: (e: mapboxgl.MapboxEvent<MouseEvent>) => void =
    !isExpanded
      ? () => {
          zoomTo(hydrophone.LocationName)
        }
      : (event) => {
          const target = event.originalEvent.target as HTMLElement

          if (target && target.tagName.toLowerCase() === 'input') {
            // User clicked on an input.
            target.focus()
          }
        }

  return (
    <Marker
      latitude={cluster.geometry.coordinates[1]}
      longitude={cluster.geometry.coordinates[0]}
      onClick={onClickCallback}
    >
      <div className="hm-Marker">
        <div
          className={`hm-SingleMarker hm-HydrophonePopup hm-LineSpacing ${
            isExpanded ? 'hm-ExpandedMarker' : ''
          }`}
        >
          <div className="hm-StationLabel">
            <h1 className={colorClass}>
              {isExpanded
                ? hydrophone.LocationName
                : hydrophone.LocationName.split(' ')[0]}
            </h1>
            <i className={`hm-LabelIcon ${colorClass} fas fa-signal-stream`} />
          </div>
          {isExpanded && <ExpandedSingleMarker hydrophone={hydrophone} />}
        </div>
      </div>
    </Marker>
  )
}

export default SingleMarker
