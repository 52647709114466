import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './components/App/App'

import './index.scss'

declare global {
  interface Window {
    HydrophoneMap: any
  }
}

export type HydrophoneMapOptions = {
  initialZoom: number
  initialLatitude: number
  initialLongitude: number
}

type HydrophoneMapOptionsWithoutDefaults = Partial<HydrophoneMapOptions>

window.HydrophoneMap = {
  mount: (
    mountingElementSelector: string = '#hydrophoneMapRoot',
    options: HydrophoneMapOptionsWithoutDefaults
  ) => {
    const mountingElement = document.querySelector(mountingElementSelector)
    const root = ReactDOM.createRoot(mountingElement as HTMLElement)

    // Copy options so we can provide default values
    let _options = {
      initialZoom: options.initialZoom || 6,
      initialLatitude: options.initialLatitude || 51,
      initialLongitude: options.initialLongitude || -130, // or -126 for mobile
    }

    root.render(
      <React.StrictMode>
        <App options={_options} />
      </React.StrictMode>
    )
  },
}
