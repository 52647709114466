import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { Hydrophone } from '../../types/Hydrophone'
import CoordTransformer from '../../utils/CoordTransformer'
import MapWrapper from './MapWrapper'

import legend from './legend.json'
import hydrophones from './hydrophone-locations.json'
import { HydrophoneMapOptions } from '../..'

const DataWrapper = ({
  options,
}: {
  options: HydrophoneMapOptions
}): JSX.Element => {
  const liveDataQuery = useQuery(['live-data-summary'], async () => {
    const response = axios.get(
      'https://dashboard-api.soundspace.workers.dev/api/v1/summary'
    )
    return response
  })

  if (liveDataQuery.isLoading) {
    return <div>Loading...</div>
  }
  if (liveDataQuery.isError) {
    return <div>There was an error.</div>
  }
  if (liveDataQuery.isSuccess && liveDataQuery.data) {
    const markers: Hydrophone[] = hydrophones.map((d) => {
      return {
        ...d,
        Latitude: CoordTransformer.decimalMinutesToDecimalDegrees(d.Latitude),
        Longitude: CoordTransformer.decimalMinutesToDecimalDegrees(d.Longitude),
      }
    })

    return (
      <MapWrapper
        options={options}
        markers={markers}
        legend={legend}
        liveDataSummary={liveDataQuery.data.data.data}
      />
    )
  }

  return <div>Queries did not execute correctly.</div>
}

export default DataWrapper
