import { networkSlug } from '../../../utils/StationSlug'
import { Hydrophone } from '../../../types/Hydrophone'
import { LiveDataSummaryStation } from '../../../types/LiveDataSummaryStation'
import { useQuery } from '@tanstack/react-query'
import { FixTypeLater } from '../../../types/FixTypeLater'
import lodash from 'lodash'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import DataPopover from '../../DataPopover/DataPopover'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useMap } from 'react-map-gl'

interface ExpandedSingleMarkerProps {
  hydrophone: Hydrophone
}

const ExpandedSingleMarker = ({
  hydrophone,
}: ExpandedSingleMarkerProps): JSX.Element => {
  const { current: map } = useMap()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const stationId = searchParams.get('stationId') || ''
  const showFullReadings = searchParams.get('showFullReadings') === 'true'

  const liveDataQuery = useQuery(['live-data-summary'])

  const liveDataSummaryStation: LiveDataSummaryStation = (
    liveDataQuery.data as FixTypeLater
  ).data.data.stations.find(
    (s: FixTypeLater) => s.name === hydrophone.LocationName
  )

  let readingsContent = <div>Data to come.</div>

  if (liveDataSummaryStation) {
    if (showFullReadings) {
      readingsContent = (
        <DataPopover show={true} stationSummary={liveDataSummaryStation} />
      )
    } else if (liveDataSummaryStation.dataSummary) {
      readingsContent = (
        <div>
          {liveDataSummaryStation?.dataSummary?.map((d: FixTypeLater) => (
            <div key={d.dataPointType}>
              <span className="hm-Label">
                {lodash.startCase(d.dataPointType)}
              </span>
              &nbsp;
              <span>{d.count}</span>
            </div>
          ))}
        </div>
      )
    } else {
      readingsContent = (
        <div>Station is in the dataset, but has no data summary available.</div>
      )
    }
  }

  const mountSeaStats = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      map?.flyTo({
        zoom: 13,
        center: [hydrophone.Longitude, hydrophone.Latitude],
      })
      navigate({
        search: createSearchParams([
          ['showFullReadings', 'true'],
          ['stationId', stationId],
        ]).toString(),
      })
    },
    [navigate, stationId, map, hydrophone]
  )

  const hideSeaStats = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()

      navigate({
        search: createSearchParams([['stationId', stationId]]).toString(),
      })
    },
    [navigate, stationId]
  )

  return (
    <div className="hm-ExpandedArea">
      <div className="hm-Details">
        <div>
          <span className="hm-Label">Network</span>
          <span className={`hm-Value ${networkSlug(hydrophone.Territory)}`}>
            {hydrophone.Network}
          </span>
          <span className="hm-Separator">•</span>
          <span className="hm-Label">Depth</span>
          <span className="hm-Value">{hydrophone['Depth [m]']}m</span>
          <span className="hm-Separator">•</span>
          <span className="hm-Value">
            {[hydrophone.Latitude, hydrophone.Longitude]
              .map((c) => c.toFixed(3))
              .join(' / ')}
          </span>
        </div>
        <div>
          {liveDataSummaryStation?.dataSummary ? (
            showFullReadings ? (
              <button className={`hm-Button hm-Close`} onClick={hideSeaStats}>
                <div className="hm-CloseIcon">×</div>
              </button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
      <h4>{showFullReadings ? 'Full readings' : 'Latest readings'}</h4>
      <div className="hm-Readings">{readingsContent}</div>
      {/* <div className={`hm-FullReadings`}></div> */}
      <p className="hm-LinkOut">
        {liveDataSummaryStation?.dataSummary ? (
          !showFullReadings ? (
            <button className={`hm-Button`} onClick={mountSeaStats}>
              <i className="fas fa-chart-column" /> See full readings for this
              station
            </button>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </p>
    </div>
  )
}

export default ExpandedSingleMarker
