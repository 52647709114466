import { useState } from 'react'

import { Hydrophone } from '../../types/Hydrophone'
import { Legend as LegendType } from '../../types/Legend'
import LegendHydrophone from './LegendHydrophone'
import LegendLocation from './LegendLocation'
import { useWindowWidth } from '../../utils/useWindowWidth'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import './Legend.scss'

interface LegendProps {
  hydrophones: Hydrophone[]
  legend: LegendType
  zoomTo: (name: string) => void
  lowZIndex?: boolean
  zoomValue?: number
}

export const Legend = ({
  hydrophones,
  legend,
  zoomTo,
  lowZIndex,
}: LegendProps): JSX.Element => {
  // TODO: Restore hovering
  const { isMobile } = useWindowWidth()

  const navigate = useNavigate()

  const [collapsed, setCollapsed] = useState<boolean>(isMobile)

  return (
    <div
      className={`hm-Legend ${lowZIndex ? 'hm-LowZIndex' : ''} ${
        collapsed ? 'hm-Collapsed' : 'hm-Expanded'
      }`}
      onClick={() => {
        setCollapsed(!collapsed)
      }}
    >
      {collapsed && (
        <div className="hm-Menu">
          <div className="hm-Hamburger">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="hm-SelectBox">
            Select hydrophone location{' '}
            <span className="hm-DownArrow">
              <i className="fas fa-caret-down" />
            </span>
          </div>
        </div>
      )}
      {!collapsed && (
        <div className="hm-Content">
          <div className="hm-Flex">
            <h2>Hydrophone locations</h2>
            <button
              className="hm-Button hm-Close"
              onClick={() => setCollapsed(true)}
            >
              <div className="hm-CloseIcon">×</div>
            </button>
          </div>
          {legend.map((region) => {
            return (
              <div
                key={region.Region}
                className={`hm-${region.Region.replace(' ', '')}`}
              >
                <h3
                  className="hm-Region"
                  onClick={(e) => {
                    e.stopPropagation()
                    navigate({
                      search: createSearchParams([
                        ['regionId', region.Region],
                      ]).toString(),
                    })
                  }}
                >
                  {region.Region}
                </h3>
                {region.Locations.map((location) => (
                  <LegendLocation
                    key={location.Territory.Name}
                    location={location}
                  >
                    {location.Hydrophones.map((hydrophone) => (
                      <LegendHydrophone
                        isMobile={isMobile}
                        key={hydrophone}
                        territory={
                          hydrophones.find(
                            (hl) => hl.LocationName === hydrophone
                          )?.Territory || ''
                        }
                        name={hydrophone}
                        zoomToHydrophone={zoomTo}
                        // setHoveredLocation={setHoveredLocation}
                        setCollapsed={setCollapsed}
                      />
                    ))}
                  </LegendLocation>
                ))}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
