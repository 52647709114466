import { useEffect, useMemo, useState } from 'react'
import Supercluster from 'supercluster'

import { Hydrophone } from '../../../types/Hydrophone'
import { SuperclusterItem, isSuperclusterCluster } from '../../../types/Cluster'
import GroupedMarker from './GroupedMarker'
import SingleMarker from './SingleMarker'

import './Marker.scss'
import { FixTypeLater } from '../../../types/FixTypeLater'
import slugify from 'slugify'
import { useSearchParams } from 'react-router-dom'
import { MapRef } from 'react-map-gl'

type MarkerPoint = {
  type: 'Feature'
  geometry: {
    coordinates: number[]
    type: 'Point'
  }
  properties: Hydrophone
}

type SuperclusterDict = Record<
  string,
  { points: MarkerPoint[]; supercluster?: Supercluster }
>

interface MarkerLayerProps {
  hydrophones: Hydrophone[]
  viewState: { latitude: number; longitude: number; zoom: number }
  expandedId?: string
  zoomTo: (hydrophoneName: string | null) => void
  mapRef?: FixTypeLater
}

export const CLUSTER_HINT_MAP: Record<
  string,
  { clusterRadius: number; maxZoom: number; zoomHints?: Record<number, number> }
> = {
  'Southern BC': { clusterRadius: 80, maxZoom: 10 },
  'Northern Vancouver Island': {
    clusterRadius: 50,
    maxZoom: 10,
    zoomHints: {
      10: 10.5,
    },
  },
  'Central Coast': { clusterRadius: 150, maxZoom: 10 },
  'North Coast': { clusterRadius: 250, maxZoom: 9 },
}
const MAX_ZOOMS = Object.values(CLUSTER_HINT_MAP).map((o) => o.maxZoom)
const MAX_HINT_ZOOM = Math.max.apply(MAX_ZOOMS)

const SUPERCLUSTER_DEFAULT_RADIUS = 80
const SUPERCLUSTER_DEFAULT_MAX_ZOOM = 10
const SUPERCLUSTER_TOTAL_MAX_ZOOM = Math.max(
  SUPERCLUSTER_DEFAULT_MAX_ZOOM,
  MAX_HINT_ZOOM
)

const fixedbbox = [-135, 45, -115, 55] as GeoJSON.BBox

const zoomToCluster = ({
  groupingKey,
  zoomFloor,
  superclusterForGroup,
  mapRef,
  cluster,
}: {
  groupingKey: string
  zoomFloor: number
  superclusterForGroup: Supercluster
  mapRef: FixTypeLater
  cluster: { id: number | string | undefined; geometry: FixTypeLater }
}) => {
  const zoomHints = CLUSTER_HINT_MAP[groupingKey]?.zoomHints || {}
  const zoomHintKey = Object.keys(zoomHints).find(
    (key) => parseFloat(key) >= zoomFloor
  )
  const zoom = zoomHintKey
    ? zoomHints[zoomHintKey as unknown as number]
    : superclusterForGroup.getClusterExpansionZoom(parseInt(`${cluster.id}`)) +
      0.5

  console.log({ zoom, center: cluster.geometry.coordinates })

  mapRef.current?.flyTo({
    zoom,
    center: cluster.geometry.coordinates,
  })
}

const MarkerLayer = ({
  hydrophones,
  viewState,
  zoomTo,
  mapRef,
}: MarkerLayerProps): JSX.Element => {
  const [superclusterDict, setSuperclusterDict] = useState<SuperclusterDict>()
  const [expandedId, setExpandedId] = useState<string>()

  const [searchParams] = useSearchParams()

  const expandedName = searchParams.get('stationId')

  const region = searchParams.get('regionId')

  const zoomFloor = Math.floor((viewState.zoom || 1) * 2) / 2

  useEffect(() => {
    if (expandedId !== expandedName) {
      setExpandedId(undefined) // Close the box
    }

    const hydrophone = hydrophones.find(
      (h) => slugify(h.LocationName) === expandedName
    )

    if (hydrophone) {
      mapRef.current?.flyTo({
        zoom: 13,
        center: [hydrophone.Longitude, hydrophone.Latitude] as [number, number],
      })
      mapRef.current?.once('moveend', () => {
        setExpandedId(hydrophone.LocationName)
      })
    }
  }, [expandedName, expandedId, hydrophones, mapRef])

  // const [debounce_zoom] = useDebounce(viewState.zoom, 10)

  // Initialize the Supercluster instances
  const clusters = useMemo(() => {
    const superclusterDict: SuperclusterDict = {}

    // Map the hydrophones
    hydrophones.forEach((hydrophone) => {
      const groupingKey = hydrophone.Region

      if (!superclusterDict[groupingKey]) {
        superclusterDict[groupingKey] = { points: [] }
      }

      const point = {
        type: 'Feature' as const,
        geometry: {
          coordinates: [hydrophone.Longitude, hydrophone.Latitude],
          type: 'Point' as const,
        },
        properties: { ...hydrophone },
      }

      superclusterDict[groupingKey].points.push(point)
    })

    const zoomClusters: Record<
      string,
      Record<number, SuperclusterItem<Hydrophone>[]>
    > = {}

    // Fill the superclusters
    Object.keys(superclusterDict).forEach((groupingKey) => {
      const dictionaryValue = superclusterDict[groupingKey]
      const hintMap = CLUSTER_HINT_MAP[groupingKey]
      const radius = hintMap?.clusterRadius || SUPERCLUSTER_DEFAULT_RADIUS
      const maxZoom = hintMap?.maxZoom || SUPERCLUSTER_DEFAULT_MAX_ZOOM

      const _supercluster = new Supercluster({ radius, maxZoom })
      dictionaryValue.supercluster = _supercluster.load(dictionaryValue.points)

      zoomClusters[groupingKey] = {}

      for (let i = 0; i <= SUPERCLUSTER_TOTAL_MAX_ZOOM + 1; i += 0.5) {
        zoomClusters[groupingKey][i] = _supercluster.getClusters(
          fixedbbox,
          i
        ) as SuperclusterItem<Hydrophone>[]
      }
    })

    setSuperclusterDict(superclusterDict)
    return zoomClusters
  }, [hydrophones])

  useEffect(() => {
    if (region && clusters?.[region]?.[0]?.[0]) {
      const furthestOutCluster = clusters[region][0][0]
      if (isSuperclusterCluster(furthestOutCluster)) {
        zoomToCluster({
          groupingKey: region,
          mapRef,
          cluster: furthestOutCluster,
          zoomFloor,
          superclusterForGroup: superclusterDict![region]!.supercluster!,
        })
      } else {
        // TOOD: Handle this case
      }
    }
  }, [region, clusters])

  // Generate clustered markers
  const markers = useMemo(() => {
    const clusteredPoints: JSX.Element[] = []
    const singlePointsNotExpanded: JSX.Element[] = []
    const singlePointExpanded: JSX.Element[] = []

    const zoomLevel =
      zoomFloor > SUPERCLUSTER_DEFAULT_MAX_ZOOM + 1
        ? SUPERCLUSTER_DEFAULT_MAX_ZOOM + 1
        : zoomFloor

    if (!Object.keys(clusters).length) return []

    Object.keys(clusters).forEach((groupingKey) => {
      if (!superclusterDict) return
      if (!superclusterDict[groupingKey]) return

      const superclusterForGroup = superclusterDict[groupingKey].supercluster
      if (!superclusterForGroup) return

      clusters[groupingKey][zoomLevel].forEach((cluster) => {
        if (isSuperclusterCluster(cluster)) {
          clusteredPoints.push(
            <GroupedMarker
              key={`${groupingKey}-${cluster.id}`}
              cluster={cluster}
              onClickCallback={() => {
                zoomToCluster({
                  superclusterForGroup,
                  groupingKey,
                  zoomFloor,
                  mapRef,
                  cluster,
                })
              }}
            />
          )
        } else {
          const isExpanded = expandedId === cluster.properties.LocationName

          const component = (
            <SingleMarker
              cluster={cluster}
              key={`${cluster.properties.LocationName}-${isExpanded}`}
              isExpanded={isExpanded}
              zoomTo={zoomTo}
            />
          )

          if (isExpanded) {
            singlePointExpanded.push(component)
          } else {
            singlePointsNotExpanded.push(component)
          }
        }
      })
    })

    return clusteredPoints
      .concat(singlePointsNotExpanded)
      .concat(singlePointExpanded)
  }, [clusters, expandedId, superclusterDict, mapRef, zoomTo, zoomFloor])

  return <>{markers}</>
}

export default MarkerLayer
