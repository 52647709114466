import { HydrophoneName } from '../../types/Legend'
import { networkSlug } from '../../utils/StationSlug'
import StationNameWithIcon from './StationNameWithIcon'

interface LegendHydrophoneProps {
  name: HydrophoneName
  territory: string
  zoomToHydrophone: (datum: HydrophoneName) => void
  // setHoveredLocation: (hoveredLocationKey: string, isHovering: boolean) => void
  setCollapsed: (collapsed: boolean) => void
  isMobile: boolean
}

const LegendHydrophone = ({
  name,
  territory,
  zoomToHydrophone,
  // setHoveredLocation,
  isMobile,
  setCollapsed,
}: LegendHydrophoneProps): JSX.Element => {
  return (
    <li
      className={`hm-LegendHydrophone ${networkSlug(territory)}`}
      key={name}
      onClick={(event) => {
        event.stopPropagation()
        zoomToHydrophone(name)
        if (isMobile) {
          setCollapsed(true)
        }
      }}
      // onMouseOver={() => {
      //   setHoveredLocation(name, true)
      // }}
      // onMouseOut={() => {
      //   setHoveredLocation(name, false)
      // }}
    >
      <StationNameWithIcon name={name} />
    </li>
  )
}

export default LegendHydrophone
