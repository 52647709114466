import { Marker } from 'react-map-gl'

import { SuperclusterCluster } from '../../../types/Cluster'

interface GroupedMarkerProps {
  cluster: SuperclusterCluster
  onClickCallback: () => void
}

const GroupedMarker = ({
  cluster,
  onClickCallback,
}: GroupedMarkerProps): JSX.Element => {
  return (
    <Marker
      latitude={cluster.geometry.coordinates[1]}
      longitude={cluster.geometry.coordinates[0]}
      onClick={onClickCallback}
    >
      <div className="hm-Marker">
        <div className="hm-GroupedMarker">
          <i className="fas fa-signal-stream" />
        </div>
      </div>
    </Marker>
  )
}

export default GroupedMarker
