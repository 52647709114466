type PointGeometry = {
  type: 'Point'
  coordinates: [number, number]
}

type SuperclusterBase = {
  geometry: PointGeometry
  type: 'Feature'
}

export type SuperclusterCluster = SuperclusterBase & {
  id: number
  properties: {
    cluster: boolean
    cluster_id: number
    point_count: number
    point_count_abbreviated: number
  }
}

export type SuperclusterSinglePoint<T> = SuperclusterBase & {
  properties: T
}

export type SuperclusterItem<T> =
  | SuperclusterCluster
  | SuperclusterSinglePoint<T>

export const isSuperclusterCluster = <T>(
  candidate: SuperclusterItem<T>
): candidate is SuperclusterCluster => {
  return (candidate as SuperclusterCluster).id !== undefined
}
