interface StationNameWithIconProps {
  name: string
}

const StationNameWithIcon = ({
  name,
}: StationNameWithIconProps): JSX.Element => {
  return (
    <>
      <i className="fas fa-signal-stream" style={{ marginRight: '4px' }} />
      <span>{name}</span>
    </>
  )
}

export default StationNameWithIcon
