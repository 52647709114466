import React from 'react'

import { Location } from '../../types/Legend'
import { useWindowWidth } from '../../utils/useWindowWidth'

interface LegendLocationProps {
  children: React.ReactNode
  location: Location
}

const LegendLocation = ({
  children,
  location,
}: LegendLocationProps): JSX.Element => {
  const { Name, Location } = location.Territory

  return (
    <div className="hm-LegendLocation">
      <h4>{Name}</h4>
      {Location && <h5 className="hm-Territory">{Location}</h5>}
      <div>{children}</div>
    </div>
  )
}

export default LegendLocation
