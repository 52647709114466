class CoordTransformer {
  // Input string is of format 123°03'086"
  static coordToDecimal = (coord: string): number => {
    const isNegative = coord.match(/[SW]/g)?.length ? true : false

    const [degrees, minsAndSeconds] = coord.split('°')
    const [minutes, secondsAndDirection] = minsAndSeconds.split('.')
    const seconds = secondsAndDirection.substring(
      0,
      secondsAndDirection.length - 1
    )

    const decimalDegrees = +degrees
    const decimalMinutes = +minutes / 60
    const decimalSeconds = +seconds / 3600

    const decimalized = decimalDegrees + decimalMinutes + decimalSeconds

    return +`${isNegative ? '-' : ''}${decimalized.toFixed(8)}`
  }

  // Input string is of format 123°03.086W
  static decimalMinutesToDecimalDegrees = (coord: string): number => {
    const isNegative = coord.match(/[SW]/g)?.length ? true : false

    const [degrees, minsAndSecondsAndDirection] = coord.split('°')

    const decimalDegrees = +degrees

    const minsAndSeconds = minsAndSecondsAndDirection.substring(
      0,
      minsAndSecondsAndDirection.length - 1
    )

    const decimalMinuteConversion = +minsAndSeconds / 60

    const decimalized = decimalDegrees + decimalMinuteConversion

    return +`${isNegative ? '-' : ''}${decimalized.toFixed(8)}`
  }
}

export default CoordTransformer
